import { handleActions } from 'redux-actions';

const initialState = {
  isLogged: false,
};

const workerReducer = handleActions(
  {
    LOGIN: (state) => ({
      ...state,
      isLogged: true,
    }),
    LOGOUT: (state) => ({
      ...state,
      isLogged: false,
    }),
  },
  initialState,
);

export default workerReducer;