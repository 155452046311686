import axios from 'axios';

import { addLoader, removeLoader } from '../actions/loaders';

const handleRequest = (payload) => {
  const {
    baseURL = '',
    url = '',
    method = 'GET',
    bearerToken = '',
    headers = {},
    data = {},
    responseType = 'json',
  } = payload;

  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  return axios
    .request({
      baseURL,
      url,
      method,
      headers,
      responseType,
      [dataOrParams]: data,
    });
};

const apiMiddleware = ({ dispatch }) => (next) => async (action) => {
  next(action);

  if (action.type.indexOf('API_') !== 0) return;

  let resultApiCall = {
    isSuccess: true,
    data: {},
  };

  dispatch(addLoader(action.type));

  // axios default configs
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const {
    fullLoad = false,
    data = {},
    onSuccessAction = null,
    onSuccessActions = [],
    onFailureAction = null,
  } = action.payload;

  if (!fullLoad) {
    try {
      const { data } = await handleRequest(action.payload);

      if (typeof onSuccessAction === 'function') {
        await dispatch(onSuccessAction(data));
      } else if (onSuccessActions && Array.isArray(onSuccessActions)) {
        for (let i = 0; i < onSuccessActions.length; i += 1) {
          const successAction = onSuccessActions[i];
          if (typeof successAction === 'function') {
            await dispatch(successAction(data));
          }
        }
      }

      resultApiCall = {
        isSuccess: true,
        data,
      };
    } catch (error) {
      resultApiCall = {
        isSuccess: false,
        data: {},
      };
      if (typeof onFailureAction === 'function') { dispatch(onFailureAction(error)); }
    } finally {
      dispatch(removeLoader(action.type));
    }
  } else {
    let items = [];
    const perPage = (data && data.per_page) || 1000;
    let page = (data && data.page) || 1;
    let run = true;

    while (run) {
      try {
        const { payload } = action;

        payload.data = {
          ...payload.data,
          page,
          per_page: perPage,
        };

        const { data } = await handleRequest(payload);

        items = items.concat(data);

        if (data.length < perPage) {
          run = false;
        }
      } catch (error) {
        run = false;
        resultApiCall = {
          isSuccess: false,
          data: items,
        };

        if (typeof onFailureAction === 'function') { dispatch(onFailureAction(error)); }
      }

      page += 1;
    }

    if (typeof onSuccessAction === 'function') {
      await dispatch(onSuccessAction({
        result: items,
        total_count: items.length,
      }));
    } else if (onSuccessActions && Array.isArray(onSuccessActions)) {
      for (let i = 0; i < onSuccessActions.length; i += 1) {
        const successAction = onSuccessActions[i];

        if (typeof successAction === 'function') {
          await dispatch(successAction({
            result: items,
            total_count: items.length,
          }));
        }
      }
    }

    resultApiCall = {
      isSuccess: true,
      data: items,
    };

    dispatch(removeLoader(action.type));
  }

  return resultApiCall;
};

export default apiMiddleware;
