import { createAction } from 'redux-actions';

export const showTeam = createAction('API_SHOW_TEAM', (teamSlug) => ({
  baseURL: 'https://undercut-backend.marianandre.fr',
  url: `/${teamSlug}`,
  bearerToken: 'b0af8fe793a972142b9153fc6d3dc',
  onSuccessAction: createAction('STORE_TEAM'),
}));

export const saveTeam = createAction('API_POST_TEAM', (teamSlug, data) => ({
  baseURL: 'https://undercut-backend.marianandre.fr',
  url: `/${teamSlug}`,
  method: 'post',
  bearerToken: 'b0af8fe793a972142b9153fc6d3dc',
  data,
  onSuccessAction: createAction('STORE_TEAM'),
}));