import React from 'react';
import { Link } from 'react-router-dom';

// import NLRLogo from '../../assets/NLR.png';
import versions from '../../versions';

import './Logo.css';


function Logo() {
  const currentVersion = versions.reduce((acc, cur) => {
    if (!acc || cur.version > acc.version) {
      return cur;
    }
    return acc;
  }, { version: '1.0.0' });

  return (
    <div id="Logo">
      <Link to="/">
        <img src="/logo.png" className="logo" />
      </Link>
      <Link to="/changelog">
        <div id="version">Version {currentVersion.version}</div>
      </Link>
    </div>
  );
}

export default Logo;
