import React, { PureComponent } from 'react';
import Logo from '../Logo';
import Navigation from '../Navigation';

import './Sidebar.css';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu() {
    const { menuOpen } = this.state;

    this.setState({ menuOpen: !menuOpen });
  }

  render() {
    const { currentRoute } = this.props;
    const { menuOpen } = this.state;

    return (
      <div id="Sidebar" className={`${menuOpen ? 'active' : ''}`}>
        <div id="Sidebar-header">
          <Logo />

          <div id="mobile-title" style={currentRoute.style}>{currentRoute.title}</div>

          <div
            id="Mobile-menu"
            onClick={() => this.toggleMenu()}
          >
            {menuOpen ? 'Fermer' : 'Menu'}
          </div>
        </div>

        <Navigation
          currentRoute={currentRoute}
          onClickLink={() => this.setState({ menuOpen: false })}
          />
      </div>
    );
  }
}

export default Sidebar;
