/* eslint-disable react/jsx-props-no-spreading */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

class PrivateRoute extends PureComponent {
  render() {
    const { isLogged, children } = this.props;

    if (isLogged) {
      return (
        <Route {...this.props}>
          {children}
        </Route>
      );
    }

    return (<Redirect to="/login" push />);
  }
}

PrivateRoute.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function mapStateToProps(state) {
  const { isLogged } = state.worker;

  return { isLogged };
}

export default connect(mapStateToProps)(PrivateRoute);
