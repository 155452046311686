import React, { PureComponent } from 'react';

import './Content.css';

class Content extends PureComponent {
  render() {
    const { children } = this.props;

    return (
      <div id="Content">
        {children}
      </div>
    );
  }
}

export default Content;
