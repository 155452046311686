const versions = [
  {
    version: '1.5',
    date: '19-nov-2022',
    items: [
      'Undercut version',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  },
  {
    version: '1.4',
    date: '30-janv-2022',
    items: [
      'Mise à jour de la durée de la practice pre-course',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  },
  {
    version: '1.3',
    date: '19-janv-2022',
    items: [
      'Mise à jour du logo Demos',
      'Mise à jour pour les Daytona 24',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  },
  {
    version: '1.2',
    date: '01-fev-2021',
    items: [
      'Demos Racing'
    ]
  },
  {
    version: '1.1.1',
    date: '21-nov-2020',
    items: [
      'Ajout du nombre de tours à faire pour chaque relais',
      'Redirige sur la page demandée après la connexion et lors du rafraichissement de la page',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  },
  {
    version: '1.1.0',
    date: '21-nov-2020',
    items: [
      'Amélioration des calculs des relais',
      'Ajout de la fonctionnalité "Heure réelle de début"',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  },
  {
    version: '1.0.0',
    date: '7-nov-2020',
    items: [
      'Première version du calculateur',
      '/!\\ Le "temps d\'un arret au stand" n\'est pas pris en compte dans le calcul des relais /!\\'
    ]
  }
];

export default versions;