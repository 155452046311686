import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import loadersReducer from './loaders';
import teamsReducer from './teams';
import workerReducer from './worker';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  worker: workerReducer,
  loaders: loadersReducer,
  teams: teamsReducer,
});

export default createRootReducer;
