import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { Icon, Input, Label, Button, Table } from 'semantic-ui-react'
import moment from 'moment';
import { isEqual } from 'lodash';

import routes from '../../routes';

import {
  mutiplyDuration,
  divideDurations,
  formatMomentDuration,
  times,
} from '../../utils';

import {
  showTeam as showTeamAction,
  saveTeam as saveTeamAction,
} from '../../actions/teams'

import { isLoading as isLoadingSelector } from '../../selectors/loaders';
import { selectTeam as selectTeamSelector } from '../../selectors/teams';
import { getCurrentLocation as getCurrentLocationSelector } from '../../selectors/routes';

import './Team.css';

// in minutes
const PRACTICE_DURATION = 3;
const QUALIFICATION_DURATION = 8;
const GRID_DURATION = 2;

const getCurrentRoute = (currentLocation) => {
  // Get current routes from route declaration
  const currentRoute = routes.find((i) => {
    let exact = true;
    if (i.exact === false) {
      exact = false;
    }

    return matchPath(currentLocation.pathname, { path: i.route, exact });
  });

  return currentRoute;
}

const driverColors = [
  '#2a93bd',
  '#2abd62',
  '#d4de1f',
  '#d6912f',
  '#e34646',
  '#b263cf',
]

class Team extends PureComponent {
  constructor(props) {
    super(props);

    const currentRoute = getCurrentRoute(props.currentLocation);

    const teamSlug = currentRoute ? currentRoute.slug : null;

    this.state = {
      teamSlug,
      teamTitle: currentRoute ? currentRoute.title : 'Unknown team',

      driverName: '',

      time: props.team.infos.time,
      duration: props.team.infos.duration,
      tank: props.team.infos.tank,
      consumption: props.team.infos.consumption,
      pitDuration: props.team.infos.pitDuration,

      drivers: teamSlug !== 'sprint' ? props.team.drivers : [{
        name: 'Moi',
        lapTime: '',
      }],

      practice: teamSlug !== 'sprint' ? props.team.practice : 'Moi',
      qualification: teamSlug !== 'sprint' ? props.team.qualification : 'Moi',
      grid: teamSlug !== 'sprint' ? props.team.grid : 'Moi',

      stints: props.team.stints,
    };

    this.saveInterval = null;
  }

  async componentDidMount() {
    const {
      teamSlug,
    } = this.state;
    const {
      showTeam
    } = this.props;

    if (teamSlug === 'sprint') {
      const localData = localStorage.getItem('sprint');

      if (localData) {
        const {
          time = '00:00',
          duration = '00:00',
          tank = 0,
          consumption = 0,
          pitDuration = '00:00',
          practice = 'Moi',
          qualification = 'Moi',
          grid = 'Moi',
          drivers = [{
            name: 'Moi',
            lapTime: '',
          }],
          stints = [
            ...(times(100).map(() => ({}))),
          ],
        } = JSON.parse(localData);
  
        this.setState({
          time,
          duration,
          tank: parseInt(tank, 10),
          consumption: parseFloat(consumption),
          pitDuration,
          practice,
          qualification,
          grid,
          drivers,
          stints,
        });
      }

      this.saveInterval = setInterval(() => {
        this.storeData();
      }, 1000);
    } else {
      await showTeam(teamSlug);
    }
  }

  componentDidUpdate(prevProps) {
    const { teamSlug } = this.state;
    const { team } = this.props;

    if (teamSlug !== 'sprint' && !isEqual(prevProps.team, team)) {
      const {
        infos: {
          time,
          duration,
          tank,
          consumption,
          pitDuration,
        },
        practice,
        qualification,
        grid,
        drivers,
        stints,
      } = team;

      this.setState({
        time,
        duration,
        tank,
        consumption,
        pitDuration,
        practice,
        qualification,
        grid,
        drivers,
        stints,
      });
    }
  }

  componentWillUnmount() {
    const { teamSlug } = this.state;

    if (teamSlug === 'sprint') {
      clearInterval(this.saveInterval);
    }
  }

  storeData() {
    const {
      time,
      duration,
      tank,
      consumption,
      pitDuration,
      practice,
      qualification,
      grid,
      drivers,
      stints
    } = this.state;

    localStorage.setItem('sprint', JSON.stringify({
      time,
      duration,
      tank: parseInt(tank, 10),
      consumption: parseFloat(consumption),
      pitDuration,
      practice,
      qualification,
      grid,
      drivers,
      stints
    }));
  }

  async saveData() {
    const {
      teamSlug,
      time,
      duration,
      tank,
      consumption,
      pitDuration,
      practice,
      qualification,
      grid,
      drivers,
      stints
    } = this.state;
    const { saveTeam } = this.props;

    const requestData = {
      name: teamSlug,
      infos: {
        time,
        duration,
        tank: parseInt(tank, 10),
        consumption: parseFloat(consumption),
        pitDuration,
      },
      practice,
      qualification,
      grid,
      drivers,
      stints,
    }

    await saveTeam(teamSlug, requestData);
  }

  changeInput(inputName, value) {
    this.setState({ [inputName]: value })
  }

  setRealPitTime(stintIdx, hour) {
    const { stints } = this.state;

    if (stintIdx < stints.length) {
      const newStints = [...stints];
      newStints[stintIdx].realBeginHour = hour;

      this.setState({ stints: newStints });
    }
  }

  resetRealPitTime(stintIdx) {
    this.setRealPitTime(stintIdx, '');
  }

  addDriver() {
    const { driverName, drivers } = this.state;

    if (driverName.trim().length === 0) { return; }

    const newDrivers = [...drivers];

    newDrivers.push({
      name: driverName,
      lapTime: '',
    });

    this.setState({ drivers: newDrivers, driverName: '' });
  }

  removeDriver(driverName) {
    const {
      drivers,
      practice,
      qualification,
      grid,
      stints,
    } = this.state;

    const newDrivers = drivers.filter((d) => d.name !== driverName);

    const newPractice = practice === driverName ? '-1' : practice;
    const newQualification = qualification === driverName ? '-1' : qualification;
    const newGrid = grid === driverName ? '-1' : grid;

    const newStints = stints.map((stint) => ({
      name: stint.name === driverName ? '-1' : stint.name,
      realBeginHour: '',
    }));

    this.setState({
      drivers: newDrivers,
      practice: newPractice,
      qualification: newQualification,
      grid: newGrid,
      stints: newStints,
    });
  }

  changeDriver(type, index, driverName) {
    const { stints } = this.state;

    if (['practice', 'qualification', 'grid'].indexOf(type) !== -1) {
      this.changeInput(type, driverName)
    } else {
      const newStints = [...stints];
      newStints[index].name = driverName;
      newStints[index].realBeginHour = '';

      this.setState({ stints: newStints });
    }
  }

  changeDriverLapTime(driverName, driverLapTime) {
    const { drivers } = this.state;

    const newDrivers = [...drivers];

    const driverIndex = newDrivers.findIndex((d) => d.name === driverName);

    if (driverIndex > -1) {
      newDrivers[driverIndex].lapTime = driverLapTime;
      this.setState({ drivers: newDrivers });
    }
  }

  computeDriverTime(name) {
    const { stints, teamSlug } = this.state;

    const driverTime = moment.duration('00:00', 'HH:mm');
    let stopProcess = false;

    stints.forEach((stint, stintIndex) => {
      if (stint.isLast) { stopProcess = true; }

      if (!stopProcess && (stint.name === name || teamSlug === 'sprint')) {
        const stintData = this.computeStint(stintIndex);
        const stintTime = stintData.stintTime.replace('h', ':').replace('m', ':').replace('s', '').split(':');

        const momentStintTime = moment.duration({
          hours: stintTime[0],
          minutes: stintTime[1],
          seconds: stintTime[2],
        });

        if (momentStintTime.asMilliseconds() > 0) {
          driverTime.add(momentStintTime.asMilliseconds(), 'ms');
        }
      }
    });

    return formatMomentDuration(driverTime, true);
  }

  computeStint(stintIndex) {
    const {
      teamSlug,
      time,
      tank,
      duration,
      consumption,
      pitDuration,
      drivers,
      stints,
    } = this.state;

    let currentStint = stints[stintIndex];

    if (!currentStint) {
      return {
        hour: '-',
        raceTime: '-',
        stintTime: '-',
        fuelToAdd: '-',
      }
    }

    if (teamSlug === 'sprint') {
      currentStint = {
        name: 'Moi',
        realBeginHour: '',
      };
    }

    let isDone = false;
    const lapPerStint = ((tank || 0) / (consumption || 1)).toFixed(2);
    const flooredStintLaps = Math.floor(lapPerStint);

    const startRaceHour = moment(time, 'HH:mm')
      .add(PRACTICE_DURATION, 'minutes') // practice
      .add(QUALIFICATION_DURATION, 'minutes') // qualification
      .add(GRID_DURATION, 'minutes'); // grid
    
    let realStartRaceHour = startRaceHour.clone();

    // Real start hour
    // If the theoretical stint hour and the real one are bot the same
    const firstStint = stints[0];

    if (!!firstStint.realBeginHour
      && !startRaceHour.isSame(moment(firstStint.realBeginHour, 'HH:mm:ss'))) {
      realStartRaceHour = moment(firstStint.realBeginHour, 'HH:mm:ss');
    }

    // Compute raceTime
    // raceTime = moment object
    const raceTime = stints.reduce((momentObject, stint, idx) => {
      if (teamSlug !== 'sprint' && !stint.name) { return momentObject; }

      if (idx < stintIndex) {
        const driverName = teamSlug === 'sprint' ? 'Moi' : (stint.name || '');
        const driver = drivers.find((d) => d.name === driverName);

        if (driver) {
          const parsedLapTime = driver.lapTime.split(':');

          const momentLapTime = moment.duration({
            minutes: parsedLapTime[0],
            seconds: parsedLapTime[1],
          });

          const momentStintTime = mutiplyDuration(momentLapTime, flooredStintLaps);

          const theoreticalStintBeginingHour = realStartRaceHour.clone().add(momentObject);

          if (!!stint.realBeginHour) {
            const onlyTimeBeginningOfStint = moment({
              hours: theoreticalStintBeginingHour.hours(),
              minutes: theoreticalStintBeginingHour.minutes(),
              seconds: theoreticalStintBeginingHour.seconds(),
            });
            const realBeginHour = moment(stint.realBeginHour, 'HH:mm:ss');
            const onlyTimeRealBeginningOfStint = moment({
              hours: realBeginHour.hours(),
              minutes: realBeginHour.minutes(),
              seconds: realBeginHour.seconds(),
            });

            if (!onlyTimeBeginningOfStint.isSame(onlyTimeRealBeginningOfStint)) {
              let diffTime = onlyTimeRealBeginningOfStint.diff(onlyTimeBeginningOfStint);

              const momentDiffTime = moment.duration({
                milliseconds: diffTime
              });

              momentObject.add(momentDiffTime);
            }
          }

          // Get current Stint hour of begining
          momentObject.add(momentStintTime);

          //    
          // If hour + stintTime > time + duration
          // = Last stint
          const parsedDurationRace = duration.split(':');
          const durationRace = moment.duration({
            hours: parsedDurationRace[0],
            minutes: parsedDurationRace[1],
            seconds: parsedDurationRace[2],
          });
          const endRaceHour = realStartRaceHour.clone().add(durationRace);

          const endOfStintHour = realStartRaceHour.clone().add(momentObject);

          if (endOfStintHour.isSameOrAfter(endRaceHour)) {
            isDone = true;
          }
        }
      }

      return momentObject;
    }, moment.duration('00:00', 'HH:mm'));

    // Compute hour
    const hour = realStartRaceHour.clone().add(raceTime);

    // Compute stintTime
    let stintTime = moment.duration('00:00', 'HH:mm');
    const driver = drivers.find((driver) => currentStint.name === driver.name);

    if (driver) {
      const parsedLapTime = driver.lapTime.split(':');

      const momentLapTime = moment.duration({
        minutes: parsedLapTime[0],
        seconds: parsedLapTime[1],
      });

      stintTime = mutiplyDuration(momentLapTime, flooredStintLaps)
    }

    // If hour + stintTime > time + duration
    // = Last stint
    const parsedDurationRace = duration.split(':');
    const durationRace = moment.duration({
      hours: parsedDurationRace[0],
      minutes: parsedDurationRace[1],
      seconds: parsedDurationRace[2],
    });
    const endRaceHour = realStartRaceHour.clone().add(durationRace);

    const endOfStintHour = hour.clone().add(stintTime);

    let fuelToAdd = tank;

    // Add realBeginHour offset for the currentStint
    if (!!currentStint.realBeginHour) {
      const onlyTimeBeginningOfStint = moment({
        hours: hour.hours(),
        minutes: hour.minutes(),
        seconds: hour.seconds(),
      });
      const realBeginHour = moment(currentStint.realBeginHour, 'HH:mm:ss');
      const onlyTimeRealBeginningOfStint = moment({
        hours: realBeginHour.hours(),
        minutes: realBeginHour.minutes(),
        seconds: realBeginHour.seconds(),
      });

      if (!onlyTimeBeginningOfStint.isSame(onlyTimeRealBeginningOfStint)) {
        const diffTime = onlyTimeRealBeginningOfStint.diff(onlyTimeBeginningOfStint);

        const momentDiffTime = moment.duration({
          milliseconds: diffTime
        });

        raceTime.add(momentDiffTime);
        stintTime.add(momentDiffTime);
      }
    }

    let isLast = false;
    if (endOfStintHour.isSameOrAfter(endRaceHour) && driver) {
      // Last stint
      // timeToLeft = duration - raceTime

      const timeToLeft = durationRace.clone().subtract(raceTime);

      const parsedLapTime = driver.lapTime.split(':');
      const momentLapTime = moment.duration({
        minutes: parsedLapTime[0],
        seconds: parsedLapTime[1],
      });

      const lapToDo = Math.ceil(divideDurations(timeToLeft, momentLapTime));

      fuelToAdd = (lapToDo * consumption).toFixed(2);
      stintTime = formatMomentDuration(timeToLeft, true);
      isLast = true;
    }

    return {
      isDone,
      isLast,
      hour: stintIndex === 0 ? startRaceHour.clone().format('HH[h]mm[m]ss[s]') : hour.format('HH[h]mm[m]ss[s]'),
      raceTime: formatMomentDuration(raceTime, true),
      stintTime: formatMomentDuration(stintTime, true),
      fuelToAdd,
    };
  }

  getDriverColorByName(driverName) {
    const { drivers } = this.state;

    const driverIndex = drivers.findIndex((driver) => driver.name === driverName);

    if (0 <= driverIndex && driverIndex < driverColors.length) {
      return driverColors[driverIndex];
    }

    return '#ffffff';
  }

  render() {
    const {
      teamSlug,
      driverName,

      time,
      duration,
      tank,
      consumption,
      pitDuration,

      drivers,

      stints,
    } = this.state;
    const {
      isLoadingTeam,
      isSavingTeam,
    } = this.props;

    const stintLaps = ((tank || 0) / (consumption || 1)).toFixed(2);
    const flooredStintLaps = Math.floor(stintLaps);

    return (
      <div id="Team">
        {isLoadingTeam && (
          <div className="p1">Loading...</div>
        )}
        {!isLoadingTeam && (
          <>
            <div className="row race-infos">
              <div className="column">
                <div className="elem-title">Informations de courses</div>
                <div className="row elems">
                  <div className="elem column">
                    <Input
                      size="mini"
                      type="time"
                      label="Heure de la course (HH:mm)"
                      placeholder=""
                      onChange={(e) => this.changeInput('time', e.target.value)}
                      value={time}
                    />
                  </div>
                  <div className="elem column">
                    <Input
                      size="mini"
                      type="time"
                      label="Durée de la course (HH:mm)"
                      placeholder=""
                      onChange={(e) => this.changeInput('duration', e.target.value)}
                      value={duration}
                    />
                  </div>
                  <div className="elem column">
                    <Input
                      size="mini"
                      type="number"
                      label="Capacité du réservoir (en L)"
                      placeholder=""
                      onChange={(e) => this.changeInput('tank', e.target.value)}
                      value={tank}
                    />
                  </div>
                  <div className="elem column">
                    <Input
                      size="mini"
                      type="number"
                      label="Consommation (en L)"
                      placeholder=""
                      onChange={(e) => this.changeInput('consumption', e.target.value)}
                      value={consumption}
                    />
                  </div>
                  {/* <div className="elem column">
                    <Input
                      size="mini"
                      type="time"
                      label="Temps d'un arrêt au stand"
                      placeholder=""
                      onChange={(e) => this.changeInput('pitDuration', e.target.value)}
                      value={pitDuration}
                    />
                  </div> */}
                  <div className="elem column">
                    <Label>
                      Nombre de tours par relai: ~{flooredStintLaps} ({stintLaps}) tours
                    </Label>
                  </div>
                </div>
              </div>
              <div className="column drivers">
                {teamSlug !== 'sprint' && (
                  <div className="row elem-title">
                    {drivers.length < 6 && (
                      <Input
                        size="mini"
                        label='Ajouter un pilote'
                        type='text'
                        placeholder='Tapez le prénom'
                        action={(
                          <Button
                            compact
                            size="mini"
                            color='orange'
                            type='button'
                            onClick={() => this.addDriver()}
                          >
                            Ajouter
                          </Button>
                        )}
                        onChange={(e) => this.changeInput('driverName', e.target.value)}
                        value={driverName}
                      />
                    )}
                  </div>
                )}
                <div className="p1 column">
                  <Table size="small" celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Pilote</Table.HeaderCell>
                        <Table.HeaderCell>Temps au tour moyen (minutes:secondes)</Table.HeaderCell>
                        <Table.HeaderCell>Temps d'un relai (minutes:secondes)</Table.HeaderCell>
                        <Table.HeaderCell>Temps de course</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!drivers.length && (
                        <Table.Row>
                          <Table.Cell colSpan='3'>
                            Aucun pilotes
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {!!drivers.length && drivers.map((driver, idx) => {
                        const parsedLapTime = driver.lapTime.split(':');

                        const momentLapTime = moment.duration({
                          minutes: parsedLapTime[0],
                          seconds: parsedLapTime[1],
                        });

                        const momentStintTime = mutiplyDuration(momentLapTime, flooredStintLaps);

                        const driverTime = this.computeDriverTime(driver.name);

                        return (
                          <Table.Row key={idx}>
                            <Table.Cell className="row" style={{ backgroundColor: this.getDriverColorByName(driver.name) }}>
                              {teamSlug !== 'sprint' && (<Icon name='times' size='large' className="pointer" onClick={() => this.removeDriver(driver.name)} />)} {driver.name}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                size="mini"
                                type="time"
                                placeholder=""
                                onChange={(e) => this.changeDriverLapTime(driver.name, e.target.value)}
                                value={driver.lapTime}
                              />
                            </Table.Cell>
                            <Table.Cell>{formatMomentDuration(momentStintTime) || '-'}</Table.Cell>
                            <Table.Cell>{driverTime}</Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                  {teamSlug !== 'sprint' && (
                    <div className="row">
                      <Button
                        compact
                        color='orange'
                        type='button'
                        onClick={() => this.saveData()}
                      >
                        Sauvegarder
                      </Button>
                      {isSavingTeam && ('Sauvegarde en cours...')}
                    </div>
                  )}
                  {teamSlug === 'sprint' && (
                    <div className="row">Pour les courses sprint, les données sont sauvegardées automatiquement toutes les 2 secondes sur votre pc.</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row p1">
              <Table size="small" celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Jalon</Table.HeaderCell>
                    <Table.HeaderCell>Heure</Table.HeaderCell>
                    {teamSlug !== 'sprint' && (<Table.HeaderCell>Heure réel de début</Table.HeaderCell>)}
                    <Table.HeaderCell>Temps de course</Table.HeaderCell>
                    <Table.HeaderCell>Temps du jalon</Table.HeaderCell>
                    <Table.HeaderCell>Fuel à ajouter</Table.HeaderCell>
                    {teamSlug !== 'sprint' && (<Table.HeaderCell>Pilote</Table.HeaderCell>)}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {['practice', 'qualification', 'grid'].map((e, idx) => {
                    let currentTime = moment(time, 'HH:mm');
                    let currentDuration = '00h03m00s';
                    if (idx > 0) {
                      currentTime.add(3, 'minutes');
                      currentDuration = '00h08m00s';
                    }
                    if (idx > 1) {
                      currentTime.add(8, 'minutes');
                      currentDuration = '00h03m00s';
                    }

                    return (
                      <Table.Row key={idx}>
                        <Table.Cell>{e}</Table.Cell>
                        <Table.Cell>{currentTime.format("HH[h]mm[m]ss[s]")}</Table.Cell>
                        {teamSlug !== 'sprint' && (<Table.Cell>-</Table.Cell>)}
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>{currentDuration}</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        {teamSlug !== 'sprint' && (
                          <Table.Cell style={{ backgroundColor: this.getDriverColorByName(this.state[e]) }}>
                            <select
                              value={this.state[e] || '-1'}
                              onChange={(elem) => this.changeDriver(e, idx, elem.target.value)}
                            >
                              <option value="-1">Selectionner un pilote</option>
                              {drivers.map((driver, idx2) => (
                                <option key={`${idx}-${idx2}`} value={driver.name}>{driver.name}</option>
                              ))}
                            </select>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )
                  })}

                  {stints.map((stint, idx) => {
                    const stintData = this.computeStint(idx);

                    if (stintData.isDone && idx > 0) {
                      const previousStintData = this.computeStint(idx - 1);
                      const isPreviousDone = previousStintData.isDone;

                      if (!isPreviousDone) {
                        const parsedDuration = duration.split(':');
                        const momentDuration = moment.duration({
                          hours: parsedDuration[0],
                          minutes: parsedDuration[1],
                          seconds: parsedDuration[2],
                        });

                        let momentHour = moment(time, 'HH:mm')
                        momentHour.add(PRACTICE_DURATION, 'minutes') // practice
                          .add(QUALIFICATION_DURATION, 'minutes') // qualification
                          .add(GRID_DURATION, 'minutes'); // grid

                        const firsStint = stints[0];
                        if (firsStint && firsStint.realBeginHour && moment(firsStint.realBeginHour, 'HH:mm:ss').isValid()) {
                          momentHour = moment(firsStint.realBeginHour, 'HH:mm:ss');
                        }

                        return (
                          <Table.Row key={idx}>
                            <Table.Cell>Fin de course</Table.Cell>
                            <Table.Cell>{momentHour.add(momentDuration).format('HH[h]mm[m]ss[s]')}</Table.Cell>
                            {teamSlug !== 'sprint' && (<Table.Cell></Table.Cell>)}
                            <Table.Cell className="bold">{formatMomentDuration(momentDuration, true)}</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            {teamSlug !== 'sprint' && (<Table.Cell></Table.Cell>)}
                          </Table.Row>
                        );
                      }

                      return (
                        <Table.Row key={idx} />
                      )
                    }

                    return (
                      <Table.Row key={idx}>
                        <Table.Cell>{`Relai ${idx + 1}`}</Table.Cell>
                        <Table.Cell>{stintData.hour}</Table.Cell>
                        {teamSlug !== 'sprint' && (<Table.Cell>
                          <Input
                            size="mini"
                            type="time"
                            step="1"
                            value={stint.realBeginHour || stintData.hour.replace('h', ':').replace('m', ':').replace('s', '')}
                            placeholder=""
                            onChange={(e) => this.setRealPitTime(idx, e.target.value)}
                          />
                          {stint.realBeginHour && (
                            <>
                              <Button
                                size="mini"
                                className="ml1 red"
                                onClick={() => this.resetRealPitTime(idx)}
                              >
                                Reset
                              </Button>
                            </>
                          )}
                        </Table.Cell>)}
                        <Table.Cell>{stintData.raceTime}</Table.Cell>
                        <Table.Cell>{stintData.stintTime}</Table.Cell>
                        <Table.Cell>
                          {stintData.fuelToAdd}L ({Math.floor(stintData.fuelToAdd / consumption)} tours)
                          {stintData.isLast && (
                            <>
                              <br/>
                              <span>Il est préférable d'ajouter {(parseFloat(stintData.fuelToAdd) + parseFloat(consumption)).toFixed(2)}L</span>
                            </>
                          )}
                        </Table.Cell>
                        {teamSlug !== 'sprint' && (
                          <Table.Cell style={{ backgroundColor: this.getDriverColorByName((stint || {}).name || '') }}>
                            <select
                              value={(stint || {}).name || '-1'}
                              onChange={(elem) => this.changeDriver('stint', idx, elem.target.value)}
                            >
                              <option value="-1">Selectionner un pilote</option>
                              {drivers.map((driver, idx2) => (
                                <option key={idx2} value={driver.name}>{driver.name}</option>
                              ))}
                            </select>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
      </div>
    );
  }
}

Team.defaultProps = {
  team: {
    name: 'titan',
    infos: {
      time: '19:00',
      duration: '01:00',
      tank: 75,
      consumption: 3.6,
      pitDuration: '01:30',
    },
    drivers: [],

    practice: '-1',
    qualification: '-1',
    grid: '-1',
    stints: [
      ...(times(100).map(() => ({}))),
    ],
  },
  currentLocation: {
    pathname: '',
  }
}

Team.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string,
    infos: PropTypes.shape({
      time: PropTypes.string,
      duration: PropTypes.string,
      tank: PropTypes.number,
      consumption: PropTypes.number,
      pitDuration: PropTypes.string,
    }),
    drivers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      lapTime: PropTypes.string,
    })),
    practice: PropTypes.string,
    qualification: PropTypes.string,
    grid: PropTypes.string,
    stints: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      realBeginHour: PropTypes.string,
    })),
  }),

  currentLocation: PropTypes.shape({
    pathname: PropTypes.string,
  }),

  isLoadingTeam: PropTypes.bool.isRequired,
};

const mapDispacthToProps = {
  showTeam: showTeamAction,
  saveTeam: saveTeamAction,
}

function mapStateToProps(state) {
  const currentLocation = getCurrentLocationSelector(state);
  const currentRoute = getCurrentRoute(currentLocation);

  return {
    team: currentRoute ? selectTeamSelector(state, currentRoute.slug) : null,
    currentLocation: getCurrentLocationSelector(state),
    isLoadingTeam: isLoadingSelector(state, showTeamAction.toString()),
    isSavingTeam: isLoadingSelector(state, saveTeamAction.toString()),
  };
}

export default connect(mapStateToProps, mapDispacthToProps)(Team);
