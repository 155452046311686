import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';

import routes from '../../routes';

import './Navigation.css';

class Navigation extends PureComponent {
  render() {
    const { currentRoute, onClickLink } = this.props;

    const menuRoutes = routes.filter((r) => r.showInMenu);

    return (
      <div id="Navigation">
        <div className="navigation-title">Choississez votre équipe</div>
        <List link inverted>
          {menuRoutes.map((r) => (
            <Link key={r.slug} to={r.route} onClick={() => onClickLink()}>
              <List.Item
                active={r.slug === currentRoute.slug}
                style={r.style}
              >
                {r.title}
              </List.Item>
            </Link>
          ))}
        </List>
      </div>
    );
  }
}

export default Navigation;
