import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';

import Sidebar from '../Sidebar';
import Content from '../Content';

import { getCurrentLocation as getCurrentLocationSelector } from '../../selectors/routes';

import './App.css';

function App(props) {
  const { currentLocation, routes = [], children, isLogged } = props;

  // Get current routes from route declaration
  const currentRoute = routes.find((i) => {
    let exact = true;
    if (i.exact === false) {
      exact = false;
    }
    return matchPath(currentLocation.pathname, { path: i.route, exact });
  });

  return (
    <div id="App">
      {isLogged && (<Sidebar currentRoute={currentRoute} />)}

      <Content currentRoute={currentRoute}>
        {children}
      </Content>
    </div>
  );
}

App.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  isLogged: PropTypes.bool.isRequired,

  currentLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    currentLocation: getCurrentLocationSelector(state),
    isLogged: state.worker.isLogged,
  };
}

export default connect(mapStateToProps)(App);
