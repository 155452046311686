import React from 'react';

import './Index.css';

function Index() {
  return (
    <div id="Index">
      {'Choississez votre équipe dans le menu'}
    </div>
  );
}

export default Index;
