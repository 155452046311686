import Index from './containers/Index';
import Changelog from './containers/Changelog';
import Login from './containers/Login';
import Team from './containers/Team';

const routes = [
  {
    route: '/',
    slug: 'index',
    component: <Index />,
    private: true,
  },
  {
    route: '/404',
    slug: '404',
    title: '404 - Page not found',
  },
  {
    route: '/changelog',
    slug: 'changelog',
    component: <Changelog />,
    private: true,
  },
  {
    route: '/404',
    slug: '404',
    title: '404 - Page not found',
  },
  {
    route: '/login',
    slug: 'login',
    title: 'login',
    component: <Login />,
  },
  {
    route: '/sprint',
    slug: 'sprint',
    title: 'Course Sprint',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #111111'
    }
    
  },
  {
    route: '/TeamGreen',
    slug: 'teamgreen',
    title: 'Team Green',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #157e20'
    }
  },
  {
    route: '/TeamBlue',
    slug: 'teamblue',
    title: 'Team Blue',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #1346a4'
    }
  },
  {
    route: '/TeamSilver',
    slug: 'teamsilver',
    title: 'Team Silver',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #616161'
    }
  },
  {
    route: '/TeamRed',
    slug: 'teamred',
    title: 'Team Red',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #ff0000'
    }
  },
  {
    route: '/TeamGold',
    slug: 'teamgold',
    title: 'Team Gold',
    component: <Team />,
    private: true,
    showInMenu: true,
    style: {
      borderLeft: '5px solid #88852b'
    }
  },
];

export default routes;