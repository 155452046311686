import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'

import configureStore, { history } from './configureStore';
import AppRoutes from './appRoutes';
import './index.css';

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root')
);
