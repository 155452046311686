import { handleActions } from 'redux-actions';

const initialState = {
  allTeams: []
};

const teamsReducer = handleActions(
  {
    STORE_TEAM: (state, action) => {
      const allTeams = state.allTeams.filter((t) => t.name !== action.payload.name);

      allTeams.push(action.payload);

      return { ...state, allTeams };
    },
  },
  initialState,
);

export default teamsReducer;
