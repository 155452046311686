import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import {
  Redirect,
  Switch,
  Route,
} from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';

import routes from './routes';
import PrivateRoute from './privateRoute';

import App from './containers/App';

class AppRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { store, history } = this.props;

    return (
      <>
        {!hasError && (
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Route path="/">
                <App routes={routes}>
                  <Switch>
                    {routes.map((r) => {
                      const RouteComponent = r.private ? PrivateRoute : Route;

                      return (
                        <RouteComponent exact path={r.route} key={r.route}>
                          {r.component ? r.component : r.title}
                        </RouteComponent>
                      )
                    })}

                    {/* No match route */}
                    <Route>
                      <Redirect to="/404" />
                    </Route>
                  </Switch>
                </App>
              </Route>
            </ConnectedRouter>
          </Provider>
        )}
        {!!hasError && (
          <h1>An error was detected. Please refresh the website.</h1>
        )}
      </>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
AppRoutes.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};
/* eslint-enable react/forbid-prop-types */

export default AppRoutes;
