import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Segment, Header, Input, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import sha512 from 'crypto-js/sha512';

import { login as loginAction } from '../../actions/worker'

import './Login.css';

const ppp = '3b92ebc887d503d4883851f996642b4cc9c83af3e72a37488f81315a218adff9903ca520ba7c83928efc19fdf2636ed7768456b3a54d24a671600ed327e1ed99';
const e = moment.duration(7, 'days');

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      p: '',
      error: '',
      isLoading: false,
      isMoving: false,
    }
  }

  async componentDidMount() {
    const { history, login } = this.props;
    const pJSON = localStorage.getItem('info');

    if (pJSON) {
      const p = JSON.parse(pJSON);

      // If p has not changed and is not expired
      if (p.p === ppp && moment().isSameOrBefore(moment(p.expire).add(e))) {
        await login();

        this.setState({ isMoving: true }, () => {
          history.goBack()
        })
      }
    }
  }

  changeInput(inputName, value) {
    this.setState({ [inputName]: value });
  }

  login() {
    const { p } = this.state;
    const { history, login } = this.props;
    
    this.setState({ isLoading: true, error: '', });

    setTimeout(async () => {
      let error = '';

      if (sha512(p).toString() === ppp) {
        await login();

        localStorage.setItem('info', JSON.stringify({
          p: sha512(p).toString(),
          expire: moment(p.expire).add(e).format(),
        }));

        this.setState({ isMoving: true }, () => {
          history.goBack()
        })
      } else {
        error = 'Mot de passe incorrect';
        this.setState({ isLoading: false, error });
      }

    }, 2000);
  }

  render() {
    const { p, error, isLoading, isMoving } = this.state;

    return (
      <div id="Login">
        {!isMoving && (
          <Segment color="orange">
            <Header>
              Connexion
            </Header>
            <div className="column">
              {!isLoading && (
                <>
                  <Input
                    placeholder="Mot de passe"
                    type="password"
                    value={p}
                    onChange={(e) => this.changeInput('p', e.target.value)}
                  />
                  {error.length > 0 && (
                    <div className="redColor">
                      {error}
                    </div>
                  )}
                  <Button
                    style={{ marginTop: '1rem' }}
                    color='orange'
                    onClick={() => this.login()}
                  >
                    Se connecter
                  </Button>
                </>
              )}
              {!!isLoading && (
                'Connexion en cours...'
              )}
            </div>
          </Segment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  login: loginAction,
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
