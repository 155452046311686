import moment from 'moment'

const mutiplyDuration = (momentObject, times) => {
  if (moment.isDuration(momentObject)
    && !Number.isNaN(times)) {
    const milliseconds = momentObject.asMilliseconds() 

    return moment.duration({
      milliseconds: milliseconds * times,
    });
  }

  return momentObject;
}
const divideDurations = (momentObject, momentObjectDivider) => {
  if (moment.isDuration(momentObject) && moment.isDuration(momentObjectDivider)) {
    return momentObject.asMilliseconds()  / momentObjectDivider.asMilliseconds();
  }

  return 0;
}

const formatMomentDuration = (momentObject, shouldHowHours = false) => {
  if (moment.isDuration(momentObject)) {
    const hours = momentObject.hours().toString();
    const seconds = momentObject.seconds().toString();
    const minutes = momentObject.minutes().toString();

    return `${shouldHowHours ? `${hours.padStart(2, '0')}h` : ''}${minutes.padStart(2, '0')}m${seconds.padStart(2, '0')}s`;
  }

  return momentObject;
};

const times = (times) => {
  const array = [];
  let i = 0;

  while (i < times) {
    array.push(i);
    i += 1;
  }

  return array;
}

export {
  mutiplyDuration,
  divideDurations,
  formatMomentDuration,
  times,
};