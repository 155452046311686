import React from 'react';

import versions from '../../versions';

import './Changelog.css';

function Changelog() {
  return (
    <div id="Changelog">
      <h1>Changelog</h1>
      {versions.map((v) => (
        <div className="version" key={v.version}>
          <h3>Version {v.version}</h3>
          <h6>{v.date ? ` (${v.date})` : ''}</h6>
          <ul>
            {v.items.map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Changelog;
